$(".js-hasChild").each(function (v,e) {
 let text = $(e).text();
 let result = zenkaku2hankaku(text);
 console.log(result);
 // console.log(e);
 $(e).text(result);
 // console.log(value);
 });
 
 // 全角変換（半角英文字の場合、0xFEE0加算して全角へ）
 // function toZenKaku(str) {
 //   return str.replace(/[A-Za-z]/g, function (s) {
 //     return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
 //   });
 // }
 
 // 半角変換（全角英文字の場合、0xFEE0減算して半角へ）
 // function toHanKaku(str) {
 //   return str.replace(/[Ａ-Ｚａ-ｚ]/g, function (s) {
 //     return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
 //   });
 // }
 
 function zenkaku2hankaku(val) {
  var regex = /[Ａ-Ｚａ-ｚ０-９！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g;
 
  // 入力値の全角を半角の文字に置換
  value = val
    .replace(regex, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    })
    .replace(/[‐－―]/g, "-") // ハイフンなど
    .replace(/[~~]/g, "～") // チルダ
    .replace(/　/g, " "); // スペース
 
  return value;
 }
 