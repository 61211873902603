import swiper from "swiper";

var serviseNav = new swiper(".c-menu-slider", {
  direction: "horizontal",
  loop: true,
  speed: 1500,
  autoplay: false,
  spaceBetween: 30,
  centeredSlides: true,
  observer: true,
  allowTouchMove: true,
  preloadImages: false,
  autoHeight: true,
  slidesPerView: 3.5,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  breakpoints: {
    768: {
      autoplay: true,
      autoplay: {
        delay: 3000,
      },
      slidesPerView: 1
    }
    // 960: {
    //   slidesPerView: 2
    // },
    // 414: {
    //   autoHeight: false,
    //   slidesPerView: 1
    // }
  },
  navigation: {
    nextEl: '.c-menu-slider-next',
    prevEl: '.c-menu-slider-prev',
  }
});
