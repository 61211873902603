class AttributeStyles {
  constructor() {
    this.targets = document.querySelectorAll(".c-article-list-item__category");
  }

  init() {
    for (let target of this.targets) {
      let data = target.getAttribute("data-color");
      let mouseOnStyles = [
       'background: #fff',
       'border: 1px solid ' + data,
       'color: ' + data,
       'transition: 0.3s',
      ];
      let setOnStyles =  mouseOnStyles.join(';');

      let mouseOffStyles = [
       'background: ' + data,
       'border: 1px solid ' + data,
       'color: #fff',
       'transition: 0.3s',
      ];
      let setOffStyles =  mouseOffStyles.join(';');


      target.setAttribute('style', setOffStyles)
      this.mouseOnEvent(target, setOnStyles);
      this.mouseOffEvent(target, setOffStyles);
    }
  }

  mouseOnEvent(target, data) {
    target.addEventListener("mouseenter", function () {
     target.setAttribute('style', data)
     }, false);
  }

  mouseOffEvent(target, data) {
    target.addEventListener("mouseleave", function () {
     target.setAttribute('style', data)
     }, false);
  }
}

const attributeStyles = new AttributeStyles();
attributeStyles.init();
